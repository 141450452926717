import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { FloorPlan } from '../FloorPlan';

import { removeDoor, assignPinsToDoors, setActivePlan } from '../../actions/surveyActions';
import { getDependencies } from '../../actions/appActions';

import './styles/DoorList.css';

export const DoorList = () => {
    const { search } = useLocation();
    const [doorArray, setDoorArray] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showFloorPlan, setShowFloorPlan] = useState(false);
    const [plansArray, setPlansArray] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const surveySelector = useSelector((state) => state.survey);
    const { doors, totalFields, doorListLoading, activePlan } = surveySelector;

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins, plans } = floorPlanSelector;

    const handleRemoveDoor = (doorId, id) => {
        dispatch(removeDoor(doorId, id));
    }

    const handleProceedToSummary = () => {
        if (disabled) {
            setShowError(true);
        } else {
            setShowError(false);
            history.push('/survey/summary');
        }
    }

    const handleDoorChange = (e) => {
        dispatch(setActivePlan(e.target.value));
    }

    useEffect(() => {
        console.log(plans);
        if (plans) {
            let tmpPlansArray = [];

            for (const [key, value] of Object.entries(plans)) {
                tmpPlansArray.push(value);
            }

            console.log(tmpPlansArray);
            setPlansArray(tmpPlansArray);
        }
    }, [plans]);

    useEffect(() => {
        if (doors) {
            const tmpDoors = Object.values(doors);
            console.log(tmpDoors);
            const filteredDoors = tmpDoors.filter((door) => door.planRef === activePlan);
    
            setDoorArray(filteredDoors);
        }
    },[doors, activePlan])

    useEffect(() => {
        const localDoors = JSON.parse(localStorage.getItem('doors'));

        if (localDoors) {
            const localDoorsArray = Object.values(localDoors);

            if (localDoorsArray.length !== doorArray.length) {
                setDisabled(true);
            } else {
                doorArray.some((door) => {
                    if (door.completedFields !== totalFields) {
                        setDisabled(true);

                        return true;
                    }

                    setDisabled(false);
                });
            }
        }
    }, [doorArray]);

    useEffect(() => {
        dispatch(getDependencies());
    }, []);

    useEffect(() => {
        if (pins) {
            console.log(pins);
            dispatch(assignPinsToDoors(pins));
        }
    }, [pins]);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <div className="doorList">
                    <h1>Door Surveys</h1>
                    <p style={{ textAlign: 'center' }}>Browse through each door and complete a series of checks to determine if the doors are compliant/meet regulations. Once you've completed each door, click process survey.</p>
                    <h2 style={{ textAlign: 'left' }}>List of Doors</h2>
                    <div className="form__buttons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        {plansArray.length > 1 && (
                            <select onChange={handleDoorChange} value={activePlan}>
                                <option value="">Please select a floor plan</option>
                                {plansArray.map((plan) => {
                                    console.log(plan);
                                    return <option value={plan?.id}>{plan?.label}</option>
                                })}
                            </select>
                        )}
                        {activePlan && (
                            <button className="form__button secondary addDoor" onClick={() => setShowFloorPlan(!showFloorPlan)}>Add Door / Floor Plan</button>
                        )}
                    </div>
                    {activePlan ? !doorListLoading ? doorArray.length > 0 ? doorArray.map((door) => {
                        console.log(door);
                        let errorClass = '';

                        errorClass = door?.completedFields === totalFields ? 'field-completed' : 'field-incompleted';

                        return (
                            <div key={door.id} className={`doorList__container ${errorClass} ${showError && 'show'}`}>
                                {door?.ref ? (
                                    <div style={{ flex: '1' }}>Door <span className="small">({door?.ref})</span></div>
                                ) : (
                                    <div style={{ flex: '1' }}>Door <span className="small">({door?.id})</span></div>
                                )}
                                <button onClick={() => history.push(`/survey/doors/${door?.id}`)} style={{ marginRight: '1rem' }}>Edit</button>
                                <button className="remove" onClick={() => handleRemoveDoor(door?.doorId, door?.id)}>Remove</button>
                            </div>
                        );
                    }) : (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>No doors set. Please go to the floor plan to start adding doors.</div>
                    ) : (
                        <div>Loading...</div>
                    ) : (
                        <div>Please select a floor plan to start</div>
                    )}
                    {(showError || search.includes('error')) && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>You have an incomplete door inspection. Please rectify and save your doors to continue.</div>)}
                    <div className="form__buttons">
                        <button style={{ marginRight: '1rem' }} className="form__button secondary form__button--inline" onClick={() => history.push('/survey/edit')}>Edit Client Form</button>
                        <button className="form__button form__button--inline" onClick={handleProceedToSummary}>Process Survey</button>
                    </div>
                    {showFloorPlan && (
                        <FloorPlan toggle={showFloorPlan} setToggle={setShowFloorPlan} />
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
