import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Draggable from 'react-draggable';

import { removePin } from '../../actions/floorPlanActions';

import './css/FloorPlanPin.css';

export const FloorPlanPin = ({ pin, disabled, handleStop, handleEdit }) => {
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);

    return (
        <Draggable key={pin.id} defaultPosition={{ x: pin.position?.x, y: pin.position?.y }} onStop={handleStop} disabled={!disabled}>
            <div id={pin.id} className="pin" style={{ cursor: disabled ? 'grab' : 'pointer' }} onClick={() => !disabled && setToggle(!toggle)}>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" /></svg>
                {toggle ? (
                    <div className="floorPin">
                        <button onClick={() => handleEdit(pin.id)}>
                            <svg className="edit w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" /></svg>
                        </button>
                        <button onClick={() => dispatch(removePin(pin.id))}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                ) : pin?.ref && (
                    <div className="floorPin--transparent">
                        <span style={{ paddingRight: '0.5rem' }}>{pin?.ref}</span>
                    </div>
                )}
            </div>
        </Draggable>
    )
}
