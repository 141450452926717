import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { saveFloorPlan, saveLabel } from '../../actions/floorPlanActions';

import './styles/PDFRow.css';

export const PDFRow = ({ id, image, page, setPages }) => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(false);
    const [togglePreview, setTogglePreview] = useState(false);
    const [toggleLabel, setToggleLabel] = useState(false);
    const [label, setLabel] = useState(`Page ${page + 1}`);

    const handleSaveLabel = () => {
        dispatch(saveLabel(id, label));
        setToggleLabel(false);
    }
    
    const handleSelected = () => {
        setSelected(!selected);
        console.log(label);
        dispatch(saveFloorPlan(id, label));
    }

    // useEffect(() => {
    //     if (selected) {

    //     }
    // }, [selected]);

    return (
        <>
            <div className="selectorRow">
                <div className="selectorRow__image">
                    <div className="imageContainer" onClick={() => setTogglePreview(!togglePreview)}>
                        <img src={image} />
                    </div>
                </div>
                <div>
                    <div className="selectorLabel">
                        <span>{label}</span>
                    </div>
                    <div className="selectorButtons">
                        <button onClick={() => setToggleLabel(!toggleLabel)}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" /></svg>
                        </button>
                        <button className="selectorButton" onClick={handleSelected} style={{ backgroundColor: selected ? '#34D399' : '#DC2626' }}>
                            {selected ? (
                                <>
                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                                    <span>Selected</span>
                                </>
                            ) : (
                                <>
                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" /></svg>
                                    <span>Un-selected</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {toggleLabel && (
                <div className="popup">
                    <div className="popup__container">
                        <span className="title">Label</span>
                        <input type="text" value={label} onChange={(e) => setLabel(e.target.value)} />
                    </div>
                    <div className="popup__content">
                        <button type="button" onClick={() => setToggleLabel(!toggleLabel)}>Close</button>
                        <button className="save" type="button" onClick={handleSaveLabel}>Save</button>
                    </div>
                </div>
            )}
            {togglePreview && (
                <div className="popup popup--preview">
                    <div className="popupClose__container">
                        <button onClick={() => setTogglePreview(!togglePreview)}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                    <div className="popupPreview__container">
                        <img src={image} />
                    </div>
                </div>
            )}
        </>
    )
}
