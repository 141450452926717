import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setPinRef } from '../../actions/floorPlanActions';
import { saveDoorRef } from '../../actions/surveyActions';

import './css/FloorPlanPopup.css';

export const FloorPlanPopup = ({ id, setSelectedPin }) => {
    const dispatch = useDispatch();

    const [ref, setRef] = useState(true);
    
    const surveySelector = useSelector((state) => state.survey);
    const { doors } = surveySelector;
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins } = floorPlanSelector;

    const handleSave = () => {
        dispatch(setPinRef(id, ref));

        const door = Object.values(doors).find((door) => door.pinId === id);

        dispatch(saveDoorRef(door.id, ref));
        setSelectedPin(null);
    }

    const handleRefChange = (e) => {
        setRef(e.target.value);
    }

    useEffect(() => {
        const pin = pins.find((pin) => pin.id === id);

        setRef(pin.ref);
    }, [id]);

    return (
        <div className="popup">
            <div className="popup__container">
                <span className="title">Edit Pin</span>
                <input type="text" value={ref} onChange={handleRefChange} />
            </div>
            <div className="popup__content">
                <button type="button" onClick={() => setSelectedPin(null)}>Close</button>
                <button className="save" type="button" onClick={handleSave}>Continue</button>
            </div>
        </div>
    )
}
