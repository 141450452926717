import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
import axios from 'axios';
import Localbase from 'localbase';

import { SET_PIN_REQUEST, SET_PIN_SUCCESS, SET_PIN_FAILURE, UPDATE_PIN_REQUEST, UPDATE_PIN_SUCCESS, UPDATE_PIN_FAILURE, REMOVE_PIN_REQUEST, REMOVE_PIN_SUCCESS, REMOVE_PIN_FAILURE, SET_PIN_REF_REQUEST, SET_PIN_REF_SUCCESS, SET_PIN_REF_FAILURE, GENERATE_PINS_REQUEST, GENERATE_PINS_SUCCESS, GENERATE_PINS_FAILURE, UPLOAD_FLOORPLAN_REQUEST, UPLOAD_FLOORPLAN_SUCCESS, UPLOAD_FLOORPLAN_FAILURE, SAVE_LABEL_REQUEST, SAVE_LABEL_SUCCESS, SAVE_LABEL_FAILURE, SAVE_FLOORPLAN_REQUEST, SAVE_FLOORPLAN_SUCCESS, SAVE_FLOORPLAN_FAILURE, GET_PDF_PAGES_REQUEST, GET_PDF_PAGES_SUCCESS, GET_PDF_PAGES_FAILURE } from '../types/floorPlanTypes';

export const setPin = (id, x, y, planRef) => dispatch => {
    let pin = {};

    if (x && y) {
        pin = { id, position: { x, y }, ref: '', planRef };
    } else {
        pin = { id, position: { x: 10, y: 10 }, ref: '', planRef };
    }

    try {
        dispatch({ type: SET_PIN_REQUEST });

        dispatch({ type: SET_PIN_SUCCESS, payload: pin });
    } catch (error) {
        dispatch({ type: SET_PIN_FAILURE, payload: error?.response.data.errors });
    }
}

export const generateDoors = (numOfDoors, pins) => dispatch => {
    let pinArray = [];

    if (pins) {
        for (let i = 0; i < pins.length; i++) {
            const pin = pins[i];
        
            pinArray.push(pin);
        }

        if (numOfDoors > pins.length) {
            const remainingCount = numOfDoors - pins.length;

            for (let i = 0; i < remainingCount; i++) {
                const id = uuidv4();
                const pin = { id, position: { x: 10, y: 10 }, ref: '' };
            
                pinArray.push(pin);
            }
        }
    } else {
        for (let i = 0; i < numOfDoors; i++) {
            const id = uuidv4();
            const pin = { id, position: { x: 10, y: 10 }, ref: '' };
        
            pinArray.push(pin);
        }
    }

    try {
        dispatch({ type: GENERATE_PINS_REQUEST });

        dispatch({ type: GENERATE_PINS_SUCCESS, payload: pinArray });
    } catch (error) {
        dispatch({ type: GENERATE_PINS_FAILURE, payload: error?.response.data.errors });
    }

    localStorage.setItem('floorPlan', JSON.stringify(pinArray));
}

export const uploadFloorPlan = (file, url) => async dispatch => {
    const db = new Localbase('saved-uploads');

    console.log(url);

    try {
        dispatch({ type: UPLOAD_FLOORPLAN_REQUEST });

        await db.collection('floorplan-photos').add(file);

        dispatch({ type: UPLOAD_FLOORPLAN_SUCCESS, payload: url });
    } catch (error) {
        console.log(error);
        dispatch({ type: UPLOAD_FLOORPLAN_FAILURE });
    }
}

export const updatePins = (pin) => dispatch => {
    try {
        dispatch({ type: UPDATE_PIN_REQUEST });

        dispatch({ type: UPDATE_PIN_SUCCESS, payload: pin });
    } catch (error) {
        dispatch({ type: UPDATE_PIN_FAILURE, payload: error?.response.data.errors });
    }

    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        localStorage.setItem('floorPlan', JSON.stringify([...savedPins.filter(p => p.id !== pin.id), pin]));
    } else {
        localStorage.setItem('floorPlan', JSON.stringify([pin]));
    }
}

export const removePin = (id) => dispatch => {
    try {
        dispatch({ type: REMOVE_PIN_REQUEST });

        dispatch({ type: REMOVE_PIN_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: REMOVE_PIN_FAILURE, payload: error?.response.data.errors });
    }

    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        const updatedPins = savedPins.filter(pin => pin.id !== id);

        if (updatedPins) {
            localStorage.setItem('floorPlan', JSON.stringify(updatedPins));
        }
    }
} 

export const setPinRef = (id, ref) => dispatch => {
    try {
        dispatch({ type: SET_PIN_REF_REQUEST });

        dispatch({ type: SET_PIN_REF_SUCCESS, payload: { id, ref }});
    } catch (error) {
        dispatch({ type: SET_PIN_REF_FAILURE, payload: error?.response.data.errors });
    }

    let savedPins = JSON.parse(localStorage.getItem('floorPlan'));

    if (savedPins) {
        let pin = savedPins.find(pin => pin.id === id);

        pin['ref'] = ref;

        localStorage.setItem('floorPlan', JSON.stringify([...savedPins.filter(p => p.id !== pin.id), pin]));
    }
}

export const saveLabel = (id, label) => dispatch => {
    try {
        dispatch({ type: SAVE_LABEL_REQUEST });
        dispatch({ type: SAVE_LABEL_SUCCESS, payload: { id, label } });
        
        let plans = JSON.parse(localStorage.getItem('floorPlans'));
    
        if (plans) {
            plans[id].label = label;
    
            localStorage.setItem(JSON.stringify(plans));
        } else {
            const plans = {
                [id]: {
                    id,
                    label,
                    active: true,
                }
            }
            localStorage.setItem(JSON.stringify(plans, 'floorPlans'));
        }
    } catch (error) {
        dispatch({ type: SAVE_LABEL_FAILURE });
    }
}

export const saveFloorPlan = (id, label) => dispatch => {
    try {
        dispatch({ type: SAVE_FLOORPLAN_REQUEST });
        dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id, label } });
        
        let plans = JSON.parse(localStorage.getItem('floorPlans'));
    
        if (plans) {
            if (plans[id]) {
                plans[id] = !plans[id].active;
            } else {
                const tmpPlan = {
                    [id]: {
                        id,
                        label,
                        active: true,
                    }
                }

                plans = Object.assign(plans, tmpPlan);
            }
    
            localStorage.setItem('floorPlans', JSON.stringify(plans));
        } else {   
            console.log('here');
            const plans = {
                [id]: {
                    id,
                    label: label,
                    active: true,
                }
            }

            localStorage.setItem('floorPlans', JSON.stringify(plans));
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: SAVE_FLOORPLAN_FAILURE });
    }
}

export const getPDFPages = (pages) => async dispatch => {
    const db = new Localbase('saved-uploads');

    try {
        dispatch({ type: GET_PDF_PAGES_REQUEST });

        let promises = [];

        pages.forEach((page) => {
            promises.push(axios.get('/app_handler.php', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Wg-Image': page,
                    'Wg-Method': 'GET_PDF_PAGE',
                    'Wg-Key': Cookie.get('accessToken')
                }
            }));
        });

        axios.all(promises)
            .then(axios.spread((...responses) => {
                responses.forEach( async (response) => {
                    const { data } = response;
                    console.log(data);
                    await db.collection('pdf-photos').add(data, 'test');
                })
                
            }))
            .catch((error) => {
            });

    } catch (error) {
        dispatch({ type: GET_PDF_PAGES_FAILURE });
    }
}