import _ from 'lodash';

import { CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, SAVE_DOOR_REQUEST, SAVE_DOOR_SUCCESS, SAVE_DOOR_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE, ADD_DOOR_REQUEST, ADD_DOOR_SUCCESS, ADD_DOOR_FAILURE, REMOVE_DOOR_REQUEST, REMOVE_DOOR_SUCCESS, REMOVE_DOOR_FAILURE, UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE, CLEAR_DOOR_PROCEED, SET_SURVEY_COMPLETE, CLEAR_SURVEY, UPDATE_SURVEY_KEY, CLEAR_DOORLIST_PROCEED, UPDATE_TOTAL_FIELDS, ASSIGN_PINS_REQUEST, ASSIGN_PINS_SUCCESS, ASSIGN_PINS_FAILURE, SET_ACTIVE_PLAN_REQUEST, SET_ACTIVE_PLAN_SUCCESS, SET_ACTIVE_PLAN_FAILURE, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE } from "../types/surveyTypes";

const doors = JSON.parse(localStorage.getItem('doors'));
const survey = JSON.parse(localStorage.getItem('survey'));
const dependencies = JSON.parse(localStorage.getItem('dependencies'));
// const activeSurvey = localStorage.getItem('activeSurvey');

let totalFields = [];

const tmpKeyList = dependencies?.form_templates?.costs?.sections.filter((section) => {
    const { fields } = section;

    fields.map((field) => {
        totalFields.push(field?.name);
    });
});

const initialState = {
    id: survey?.id || '',
    userId: survey?.userId || 0,
    recordId: survey?.recordId || 0,
    surveyKey: survey?.surveyKey || '',
    // id: survey[activeSurvey]?.id || 0,
    // recordId: survey[activeSurvey]?.recordId || 0,
    // surveyKey: survey[activeSurvey]?.surveyKey || '',
    offline: true,
    completed: false,
    doors: doors || {},
    totalFields: totalFields.length || 0,
    floorPlans: null,
    activePlan: null,
    redirect: null,
    doorForm: null,
    doorFormProceed: false,
    doorListProceed: false,
    proceedToPDFSelector: false,
    surveyLoading: false,
    surveyErrors: null,
    doorFormLoading: false,
    doorFormErrors: null,
    doorLoading: false,
    doorErrors: null,
    doorListLoading: false,
    doorListErrors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SURVEY_REQUEST:
            return {
                ...state,
                surveyLoading: true,
                surveyErrors: null,
                floorPlans: null,
                doorListProceed: false,
                proceedToPDFSelector: false,
            }
        case CREATE_SURVEY_SUCCESS:
            return {
                ...state,
                surveyLoading: false,
                id: action.payload.id,
                recordId: action.payload.recordId,
                surveyKey: action.payload.surveyKey,
                floorPlans: action.payload.floorPlans,
                doorListProceed: !action.payload.proceedToPDFSelector,
                proceedToPDFSelector: action.payload.proceedToPDFSelector,
            }
        case CREATE_SURVEY_FAILURE:
            return {
                ...state,
                id: action.payload.id,
                surveyLoading: false,
                offline: true,
                surveyErrors: action.payload.errors,
                doorListProceed: true,
            }
        case UPDATE_SURVEY_REQUEST:
            return {
                ...state,
                surveyLoading: true,
                surveyErrors: null
            }
        case UPDATE_SURVEY_SUCCESS:
            return {
                ...state,
                recordId: action.payload?.recordId,
                surveyKey: action.payload?.surveyKey,
                surveyLoading: false,
                surveyErrors: null,
                doorListProceed: true,
            }
        case UPDATE_SURVEY_FAILURE:
            return {
                ...state,
                surveyLoading: false,
                surveyErrors: action.payload,
                doorListProceed: true,
            }
        case SAVE_DOOR_REQUEST:
            return {
                ...state,
                doorLoading: true,
                doorErrors: null
            }
        case SAVE_DOOR_SUCCESS:
        case SAVE_DOOR_FAILURE:

            let { id } = action.payload.door;
            let { ref } = action.payload.door;

            console.log(ref);

            console.log(id);
            console.log(action.payload);
            console.log(action.payload.door);

            return {
                ...state,
                doorLoading: false,
                doorFormProceed: true,
                recordId: action.payload.recordId,
                // doors: { ...state.doors, [id]: { ...state.doors[id], ...action.payload.door } },
                doors: { ...state.doors, [id]: { ...action.payload.door }},
            }
        case LOAD_DOOR_REQUEST:
            return {
                ...state,
                doorForm: null,
                doorFormLoading: true,
                doorFormErrors: null
            }
        case LOAD_DOOR_SUCCESS:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case LOAD_DOOR_FAILURE:
            return {
                ...state,
                doorFormLoading: false,
                doorForm: action.payload
            }
        case ADD_DOOR_REQUEST:
            return {
                ...state,
                redirect: null,
                doorListLoading: true,
                doorListErrors: null
            }
        case ADD_DOOR_SUCCESS:
        case ADD_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false,
                doors: { ...state.doors, [action.payload.id]: action.payload },
                redirect: action.payload.id,
            }
        case REMOVE_DOOR_REQUEST:
            return {
                ...state,
                doorListLoading: true
            }
        case REMOVE_DOOR_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                doors: _.omit(state.doors, action.payload)
            }
        case REMOVE_DOOR_FAILURE:
            return {
                ...state,
                doorListLoading: false
            }
        case CLEAR_DOOR_PROCEED:
            return {
                ...state,
                doorFormProceed: false
            }
        case SET_SURVEY_COMPLETE:
            return {
                ...state,
                completed: true
            }
        case CLEAR_SURVEY:
            return {
                ...state,
                id: 0,
                recordId: 0,
                surveyKey: '',
                offline: true,
                completed: false,
                doors: {},
                doorForm: null,
                doorFormProceed: false,
                surveyLoading: false,
                surveyErrors: null,
                doorFormLoading: false,
                doorFormErrors: null,
                doorLoading: false,
                doorErrors: null,
                doorListLoading: false,
                doorListErrors: null,
            }
        case UPDATE_SURVEY_KEY:
            return {
                ...state,
                surveyKey: action.payload
            }
        case CLEAR_DOORLIST_PROCEED:
            return {
                ...state,
                doorListProceed: false
            }
        case UPDATE_TOTAL_FIELDS:
            return {
                ...state,
                totalFields: action.payload
            }
        case ASSIGN_PINS_REQUEST:
            return {
                ...state,
                doorListLoading: true,
            }
        case ASSIGN_PINS_SUCCESS:
            let updatedDoors = state.doors;
            const pins = action.payload;

            let index = 0;

            for (const [key, value] of Object.entries(updatedDoors)) {
                value.ref = pins[index]?.ref;
                value.pinId = pins[index]?.id;
                index++;
            }

            return {
                ...state,
                doorListLoading: false,
                doors: updatedDoors,
            }
        case SET_ACTIVE_PLAN_REQUEST:
            return {
                ...state,
                doorListLoading: true,
            }
        case SET_ACTIVE_PLAN_SUCCESS:
            return {
                ...state,
                doorListLoading: false,
                activePlan: action.payload,
            }
        case SET_ACTIVE_PLAN_FAILURE:
            return {
                ...state,
                doorListLoading: false,
            }
        case SAVE_DOOR_REF_REQUEST:
            return {
                ...state,
            }
        case SAVE_DOOR_REF_SUCCESS:
            return {
                ...state,
                doors: { ...state.doors, [action.payload.id]: { ...state.doors[action.payload.id], ref: action.payload.ref }}
            }
        case SAVE_DOOR_REF_FAILURE:
            return {
                ...state,
            }
        default:
            return state;
    }
};