import React from 'react';

import Photograph from '../../images/photograph.svg';

const File = ({ field: { name, label, id, show_image }, fileSelectedHandler, existingFile, previews }) => {
    return (
        <div>
            <label className="form__label" htmlFor={id}>{label}</label>
            <div className="file__container">
                <div>
                    <div>{show_image && (<img src={Photograph} alt="Uploaded file" />)}</div>
                    <input id={id} type="file" name={name} onChange={fileSelectedHandler} />
                </div>
            </div>
            {existingFile && (<div style={{ marginTop: '1rem' }} className="thumbnail"><img src={`https://door.napfis.co.uk/${existingFile}`} alt={label} /></div>)}
            {previews[name] && (<div style={{ marginTop: '1rem' }} className="thumbnail"><img src={previews[name]} alt="Preview" /></div>)}
        </div>
    )
}

export default File;
