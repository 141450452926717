import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { getDependencies } from '../../actions/appActions';
import { getRecentSurveys } from '../../actions/dashboardActions';
import { getCosts, setCosts } from '../../actions/costsActions';
import { checkSubscription } from '../../actions/authActions';

import { ReactComponent as Report } from '../../assets/report.svg';
import { ReactComponent as Play } from '../../assets/play.svg';

import './Dashboard.css';

const Dashboard = () => {
    const dispatch = useDispatch();
    const dashboardSelector = useSelector((state) => state.dashboard);
    const authSelector = useSelector((state) => state.auth);
    const { recentSurveys, loading } = dashboardSelector;
    const { user } = authSelector;
    const [savedSurvey, setSavedSurvey] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);

        const tmp = JSON.parse(localStorage.getItem('survey'));

        setSavedSurvey(tmp);

        const costs = JSON.parse(localStorage.getItem('costs'));

        dispatch(checkSubscription());
        dispatch(getDependencies());
        dispatch(getRecentSurveys());

        if (costs) {
            dispatch(setCosts(costs));
        } else {
            dispatch(getCosts());
        }
            
    }, [])

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <h1>Door Survey App</h1>
                <p style={{ textAlign: 'center' }}><Link to="/survey">Click here</Link> to start a new survey...</p>
                {savedSurvey && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>You have an incomplete survey, would you like to continue your survey? <NavLink to="/survey">Click here</NavLink></div>)}
                <h2 style={{ textAlign: 'center' }}>Recent surveys</h2>
                <div>
                    {loading ? 
                        <div>Loading...</div> : recentSurveys ? (
                            <React.Fragment>
                                <div className="recent_surveys">
                                    <div>Client</div>
                                    <div>Building</div>
                                    <div className="desktop">Doors</div>
                                    <div className="flex justify-center">Status</div>
                                    <div className="desktop">Cost</div>
                                    <div className="flex justify-center">Report</div>
                                </div>
                                {recentSurveys.map((survey) => {
                                    return (
                                        <div className="recent_surveys entry">
                                            <div>{survey?.inspection_client_name}</div>
                                            <div>{survey?.inspection_building_name}</div>
                                            <div className="desktop">{survey?.inspection_doors}</div>
                                            <div className="flex justify-center">{survey?.survey_status}</div>
                                            <div className="desktop">{survey?.inspection_total ? (<span>£{survey?.inspection_total}</span>) : (<span>-</span>)}</div>
                                            <div className="flex justify-center">
                                                {survey?.survey_status === 'Completed' && (
                                                    <a target="_blank" href={`https://www.napfis.co.uk/survey/?${survey?.survey_secret_key}&${user?.secret_code}`}><Report /></a>
                                                )}
                                                {survey?.survey_status === 'In Progress' && survey?.survey_secret_key === savedSurvey?.surveyKey && (
                                                    <Link to="/survey/doors"><Play /></Link>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </ React.Fragment>
                        ) : <div style={{ textAlign: 'center' }}>You have no recent surveys....</div>   
                    }
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default Dashboard;
