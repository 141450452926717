export const SET_PIN_REQUEST = 'SET_PIN_REQUEST';
export const SET_PIN_SUCCESS = 'SET_PIN_SUCCESS';
export const SET_PIN_FAILURE = 'SET_PIN_FAILURE';

export const UPDATE_PIN_REQUEST = 'UPDATE_PIN_REQUEST';
export const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS';
export const UPDATE_PIN_FAILURE = 'UPDATE_PIN_FAILURE';

export const REMOVE_PIN_REQUEST = 'REMOVE_PIN_REQUEST';
export const REMOVE_PIN_SUCCESS = 'REMOVE_PIN_SUCCESS';
export const REMOVE_PIN_FAILURE = 'REMOVE_PIN_FAILURE';

export const SET_PIN_REF_REQUEST = 'SET_PIN_REF_REQUEST';
export const SET_PIN_REF_SUCCESS = 'SET_PIN_REF_SUCCESS';
export const SET_PIN_REF_FAILURE = 'SET_PIN_REF_FAILURE';

export const GENERATE_PINS_REQUEST = 'GENERATE_PINS_REQUEST';
export const GENERATE_PINS_SUCCESS = 'GENERATE_PINS_SUCCESS';
export const GENERATE_PINS_FAILURE = 'GENERATE_PINS_FAILURE';

export const UPLOAD_FLOORPLAN_REQUEST = 'UPLOAD_FLOORPLAN_REQUEST';
export const UPLOAD_FLOORPLAN_SUCCESS = 'UPLOAD_FLOORPLAN_SUCCESS';
export const UPLOAD_FLOORPLAN_FAILURE = 'UPLOAD_FLOORPLAN_FAILURE';

export const SAVE_LABEL_REQUEST = 'SAVE_LABEL_REQUEST';
export const SAVE_LABEL_SUCCESS = 'SAVE_LABEL_SUCCESS';
export const SAVE_LABEL_FAILURE = 'SAVE_LABEL_FAILURE';

export const SAVE_FLOORPLAN_REQUEST = 'SAVE_FLOORPLAN_REQUEST';
export const SAVE_FLOORPLAN_SUCCESS = 'SAVE_FLOORPLAN_SUCCESS';
export const SAVE_FLOORPLAN_FAILURE = 'SAVE_FLOORPLAN_FAILURE';

export const GET_PDF_PAGES_REQUEST = 'GET_PDF_PAGES_REQUEST';
export const GET_PDF_PAGES_SUCCESS = 'GET_PDF_PAGES_SUCCESS';
export const GET_PDF_PAGES_FAILURE = 'GET_PDF_PAGES_FAILURE';

export const CLEAR_FLOORPLAN = 'CLEAR_FLOORPLAN';