import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { PDFRow } from '../../components/PDFRow';

import { getPDFPages } from '../../actions/floorPlanActions';

import './styles/PDFImageSelector.css';

export const PDFImageSelector = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const [pages, setPages] = useState([]);
    
    const surveySelector = useSelector((state) => state.survey);
    const { floorPlans } = surveySelector;

    const handleContinue = () => {
        dispatch(getPDFPages(['https://door.webglu17.co.uk/uploads/survey/5ffc30f2c4d826.04327006.jpg', 'https://door.webglu17.co.uk/uploads/survey/5ffc30f3419c56.03687400.jpg']));
        history.push('/survey/doors');
    }

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <h1 style={{ textAlign: 'left' }}>{floorPlans.length} pages have been discovered. Please select the pages you would like to use.</h1>
                {floorPlans.map((floorPlan, index) => {
                    return <PDFRow id={floorPlan?.id} image={floorPlan?.image} page={index} />
                })}
                <div className="form__buttons">
                    <button aria-label="Continue" onClick={handleContinue} className="form__button form__button--inline" type="submit">Continue</button>
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
