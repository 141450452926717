import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';

import { clearSurvey } from '../../functions/utils';
import { CREATE_SURVEY_SUCCESS, CLEAR_SURVEY } from '../../types/surveyTypes';
import { CLEAR_FLOORPLAN } from '../../types/floorPlanTypes';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

export const DoorInspection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [savedCosts, setSavedCosts] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [costsErrors, setCostsErrors] = useState('');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setSavedCosts(JSON.parse(localStorage.getItem('costs')));
        setSurvey(JSON.parse(localStorage.getItem('survey')));
    }, []);

    useEffect(() => {
        setLoading(true);
        
        if (savedCosts) {
            const { form_templates } = JSON.parse(localStorage.getItem('dependencies'));

            let keyList = [];
            
            const tmpKeyList = form_templates?.costs?.sections.filter((section) => {
                console.log(section);
                const { fields } = section;
                
    
                fields.map((field) => {
                    keyList.push(field?.name);
                });
            });

            if (keyList.length !== Object.values(savedCosts).length) {
                setCostsErrors('There is an error with the costs. Please go to the costs tab to rectify and re-save.');
            }
    
            // keyList.forEach((name) => {
            //     if (!savedCosts[name]) {
            //         setCostsErrors('You have missing values in the costs table. Please go to the costs tab to rectify');
            //     }
            // });

        }
        
        setLoading(false);
    }, [savedCosts]);
    
    const handleContinue = () => {
        const { id, doors, recordId, surveyKey } = survey;
        
        dispatch({ type: CREATE_SURVEY_SUCCESS, payload: { id, doors, recordId, surveyKey } });

        history.push('/survey/doors');
    }

    const handleStartAgain = () => {
        dispatch({ type: CLEAR_SURVEY });
        dispatch({ type: CLEAR_FLOORPLAN});

        clearSurvey();

        history.push('/survey/new');
    }

    const renderedContent = savedCosts ? (
        <Fragment>
            {!costsErrors ? (
                <Fragment>
                    {survey ? (
                        <Fragment>
                                <div style={{ textAlign: 'center' }}>It looks like you haven't completed a previous survey. Do you wish to continue?</div>
                                <div className="form__buttons">
                                    <button className="form__button" style={{ marginRight: '1rem' }} onClick={handleContinue}>Continue</button>
                                    <button className="form__button secondary" onClick={handleStartAgain}>Start Again</button>
                                </div>
                        </Fragment>
                    ) : <Redirect to="/survey/new" />}
                </Fragment>
            ) : (
                <Fragment>
                    <div>
                        <h2 style={{ textAlign: 'center' }}>Errors found in cost page</h2>
                        <div style={{ textAlign: 'center' }}>{costsErrors}</div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    ) : (
        <Fragment>
            <div>
                <h2 style={{ textAlign: 'center' }}>Before you start, please specify the costs applicable for any repairs</h2>
                <div className="form__buttons">
                    <Link className="form__button" to="/costs">Go to costs page</Link>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                {!loading ? renderedContent : <div>Loading...</div>}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
