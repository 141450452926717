import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { v4 as uuidv4 } from 'uuid';

import { addDoor } from '../../actions/surveyActions';
import { setPin, updatePins, generateDoors } from '../../actions/floorPlanActions';

import { FloorPlanPopup } from '../FloorPlanPopup';
import { FloorPlanPin } from '../FloorPlanPin';

import FloorPlanExample from '../../assets/floorplan.png';
import LargeImageExample from '../../assets/high_res_2.jpg';

import './css/FloorPlan.css';

export const FloorPlan = ({ numOfDoors, setToggle, toggle }) => {
    const floorPlanEl = useRef(null);
    const [selectedPin, setSelectedPin] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [positionY, setPositionY] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [planWidth, setPlanWidth] = useState(0);
    const [planHeight, setPlanHeight] = useState(0);
    const [floorPlan, setFloorPlan] = useState(null);

    const dispatch = useDispatch();

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { loading, pins, plan } = floorPlanSelector;

    const surveySelector = useSelector((state) => state.survey);
    const { id, floorPlans, activePlan } = surveySelector;

    const addPin = () => {
        const pinId = uuidv4();

        let newX = (floorPlanEl.current.offsetWidth / 2) + Math.abs(positionX);
        let newY = (floorPlanEl.current.offsetHeight / 2) + Math.abs(positionY);

        if (newX > planWidth - (floorPlanEl.current.offsetWidth / 2)) {
            newX = planWidth - (floorPlanEl.current.offsetWidth / 2);
        }

        if (newY > planHeight - (floorPlanEl.current.offsetHeight / 2)) {
            newY = planHeight - (floorPlanEl.current.offsetHeight / 2);
        }

        setDisabled(true);
        setSelectedPin(null);
        dispatch(setPin(pinId, newX, newY, activePlan));
        dispatch(addDoor(id, activePlan, '', pinId));
    }

    const handleStop = (e, data) => {
        let { id } = data.node;

        let pin = pins.find((pin) => pin.id === id);

        if (pin) {
            pin.position.x = data.x;
            pin.position.y = data.y;

            dispatch(updatePins(pin));
        }
    }

    const handlePanningStop = (e) => {
        console.log(e);
        setPositionX(e.positionX);
        setPositionY(e.positionY);
    }

    const handleImageLoad = (e) => {
        setPlanHeight(e.target.height);
        setPlanWidth(e.target.width);
    }

    useEffect(() => {
        if (numOfDoors) {
            dispatch(generateDoors(numOfDoors));
        }
    }, [numOfDoors]);

    useEffect(() => {
        const floorImage = floorPlans.find((plan) => plan.id === activePlan);

        setFloorPlan(floorImage.image);
    }, [floorPlans]);

    return (
        <div className="container">
            <div className="floorPlan__header">
                <button type="button" onClick={() => setToggle(!toggle)}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
                </button>
            </div>
            <TransformWrapper
                defaultScale={1}
                defaultPositionX={0}
                defaultPositionY={1}
                onPanningStop={handlePanningStop}
                options={{
                    disabled,
                    limitToWrapper: true
                }}
            >
                {({ zoomIn, zoomOut, setTransform, ...rest }) => (
                <div className="floorPlan__container">
                    <div className="floorPlan__plan" ref={floorPlanEl}>
                        <TransformComponent>
                            {/* <img className="floorPlan__image" src={plan} alt="Floor Plan" /> */}
                            <img className="floorPlan__image" src={floorPlan} alt="Floor Plan" onLoad={handleImageLoad} />
                            {pins.filter((pin) => pin.planRef === activePlan).map((pin) => {
                                return (
                                    <FloorPlanPin pin={pin} disabled={disabled} handleStop={handleStop} handleEdit={setSelectedPin} />
                                )
                            })}
                        </TransformComponent>
                    </div>
                    <div className="floorPlan__tools">
                        <button type="button" onClick={zoomIn} disabled={disabled}>
                            <svg className={`w-6 h-6 ${disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" /><path fillRule="evenodd" d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z" clipRule="evenodd" /></svg>
                        </button>
                        <button type="button" onClick={zoomOut} disabled={disabled}>
                            <svg className={`w-6 h-6 ${disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" /><path fillRule="evenodd" d="M5 8a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
                        </button>
                        <button type="button" onClick={() => setTransform(0, 1, 1)} disabled={disabled}>
                            <svg className={`w-6 h-6 ${disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd" /></svg>
                        </button>
                        <button type="button" onClick={() => setDisabled(!disabled)}>
                            <svg className={`w-6 h-6 ${!disabled && 'disabled'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" /></svg>
                        </button>
                        <button type="button" onClick={addPin}>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" /></svg>
                        </button>
                    </div>
                </div>
                )}
            </TransformWrapper>
            {disabled ? <div style={{ color: 'black' }}>Pin Editing Mode</div> : <div style={{ color: 'black' }}>Map Mode</div>}
            {selectedPin && (
                <FloorPlanPopup id={selectedPin} setSelectedPin={setSelectedPin} />
            )}
        </div>
    );
}