import { TOGGLE_MENU, CLOSE_MENU, NAVIGATION_SUCCESS, MENU_REQUEST, MENU_SUCCESS, MENU_FAIL, GET_DEPENDENCIES_REQUEST, GET_DEPENDENCIES_SUCCESS, GET_DEPENDENCIES_FAIL } from '../types/appTypes';

const dependencies = JSON.parse(localStorage.getItem('dependencies'));

const initialState = {
    navigation: [],
    menu: dependencies?.menu || null,
    doorForm: dependencies?.form_templates.door || null,
    menuOpen: false,
    alert: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_SUCCESS:
            return {
                ...state,
                navigation: action.payload
            };
        case MENU_REQUEST:
            return {
                ...state,
                menu: null
            };
        case MENU_SUCCESS:
            return {
                ...state,
                menu: action.payload
            };
        case MENU_FAIL:
            return {
                ...state
            };
        case TOGGLE_MENU:
            return {
                ...state,
                menuOpen: !state.menuOpen
            };
        case CLOSE_MENU:
            return {
                ...state,
                menuOpen: false
            };
        case GET_DEPENDENCIES_REQUEST:
        case GET_DEPENDENCIES_FAIL:
            return {
                ...state,
                doorForm: null
            };
        case GET_DEPENDENCIES_SUCCESS:
            return {
                ...state,
                doorForm: action.payload.form_templates.door
            };
        default:
            return state;
    }
};