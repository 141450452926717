import axios from 'axios';
import Localbase from 'localbase';

export const handleForm = async (url, id, data) => {
    const fd = new FormData();

    for (const [key, value] of Object.entries(data)) {
        if (value.type === 'file') {
            if (typeof value.value === 'object') {
                // console.log('value: ' + value.value);
                // console.log('name: ' + value.value.name);
                fd.append(key, value.value, value.value.name);
            } else {
                fd.append(key, value.value);
            }
        } else {
            fd.append(key, value.value);
        }
    }

    const response = await axios.post(url, fd, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Record-Id': id,
            'Wg-Method': 'SAVE_DOOR'
        }
    });

    return response;
}

export const clearSurvey = () => {
    // let survey = JSON.parse(localStorage.getItem('survey'));
    // const surveyId = localStorage.getItem('activeSurvey');

    // delete survey[surveyId];

    // localStorage.setItem('survey', JSON.stringify(survey));

    localStorage.removeItem('survey');
    localStorage.removeItem('activeSurvey');
    localStorage.removeItem('clientData');
    localStorage.removeItem('doors');
    localStorage.removeItem('doorCosts');
    localStorage.removeItem('doorScores');
    localStorage.removeItem('summary');
    localStorage.removeItem('floorPlan');
    localStorage.removeItem('floorPlans');

    const db = new Localbase('saved-uploads');

    db.collection('door-images').delete();
    db.collection('building-photos').delete();
    db.collection('floorplan-photos').delete();
}
