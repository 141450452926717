import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import axios from 'axios';
import Localbase from 'localbase';
import Cookie from 'js-cookie';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { SET_SURVEY_COMPLETE, UPDATE_SURVEY_KEY } from '../../types/surveyTypes';

import './styles/SurveySummary.css';

export const SurveySummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [cost, setCost] = useState(0.00);
    const [failedCosts, setFailedCosts] = useState(0.00);
    const [advisoryCosts, setAdvisoryCosts] = useState(0.00);
    const [totalCost, setTotalCost] = useState(0.00);
    const [adjustment, setAdjustment] = useState(0);
    const [icon, setIcon] = useState('pass');
    const [loading, setLoading] = useState(false);
    const [doorsArr, setDoorsArr] = useState([]);
    const [floorPlansArray, setFloorPlansArray] = useState([]);
    const [systemCosts, setSystemCosts] = useState({});
    const [error, setError] = useState(null);

    const surveySelector = useSelector((state) => state.survey);
    const { id, recordId, doors, totalFields, floorPlans } = surveySelector;
    
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { plans, pins } = floorPlanSelector;

    const handleCompleteSurvey = async () => {
        setLoading(true);
        const summary = { inspection_total: totalCost, inspection_adjustment: adjustment, inspection_initial_cost: cost };

        localStorage.setItem('summary', JSON.stringify(summary));

        let survey = JSON.parse(localStorage.getItem('survey'));
        const clientData = JSON.parse(localStorage.getItem('clientData'));
        const doors = JSON.parse(localStorage.getItem('doors'));
        const doorCosts = JSON.parse(localStorage.getItem('doorCosts'));
        const doorScores = JSON.parse(localStorage.getItem('doorScores'));
        
        let doorsArray = Object.keys(doors);
        const db = new Localbase('saved-uploads');

        let tmpRecordId = 0;
        let promises = [];
        
        const fd = new FormData();

        for (const [key, value] of Object.entries(clientData)) {
            if (key === 'inspection_building_photo') {
                try {
                    const document = await db.collection('building-photos').doc(id).get();
                    
                    fd.append(key, document, document?.name);
                } catch (error) {
                    console.log(error);
                }

            } else {
                fd.append(key, value);
            }
        }

        try {
            const { data: surveyData } = await axios.post('/app_handler.php', fd, { headers: { 'Wg-Method': 'SAVE_CLIENTDATA', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': recordId }});
            
            tmpRecordId = surveyData?.record_id;

            if (surveyData?.survey_key) {
                dispatch({ type: UPDATE_SURVEY_KEY, payload: surveyData?.survey_key });

                // survey[id]?.surveyKey = surveyData?.survey_key;
                survey.surveyKey = surveyData?.survey_key;
                localStorage.setItem('survey', JSON.stringify(survey));
            }
        } catch (error) {
            setError('The survey wasn\'t fully uploaded. We have a local copy stored ready for when you establish an internet connection');
            setLoading(false);
        }
 
        doorsArray.forEach(async (i) => {
            const fd = new FormData();

            if (doors[i]?.data) {
                for (const [key, value] of Object.entries(doors[i]?.data)) {
                    if (value.type === 'file') {
                        await db.collection('door-images').doc(`${i}_${key}`).get().then(image => {
                            fd.append(image.key, image.file);
                        });
                    } else {
                        fd.append(key, value.value);
                    }
                }
            }

            if (doors[i]) {
                console.log(pins);
                console.log(floorPlans);
                console.log(doors[i]);
                const tmpPin = pins.find((pin) => pin.id === doors[i]?.pinId);
                const tmpPlan = floorPlans.find((plan) => plan.id === doors[i]?.planRef);

                console.log(tmpPin);
                console.log(tmpPlan);

                if (tmpPin) {
                    fd.append('door_pin_position', `${tmpPin.position.x}, ${tmpPin.position.y}`);
                }

                if (tmpPlan) {
                    fd.append('door_floorplan', tmpPlan.image);
                }
            }
    
            if (doorCosts[i]) {
                for (const [key, value] of Object.entries(doorCosts[i])) {
                    if (doorScores[i]) {
                        for (const [formKey, formValue] of Object.entries(doorScores[i])) {
                            if (formValue !== "1.0" && key === formKey) {
                                fd.append(`costs_${key}`, value);
                            }
                        }
                    }
                }
            }

            promises.push(axios.post('/app_handler.php', fd, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    // 'WG-EntryId': survey[id]?.doors[i]?.doorId || survey[id]?.doors[i]?.id,
                    'WG-EntryId': survey?.doors[i]?.doorId || survey?.doors[i]?.id,
                    'WG-RecordId': tmpRecordId,
                    'Wg-Completed': 1,
                    'Wg-Method': 'SAVE_DOOR',
                    'Wg-Key': Cookie.get('accessToken')
                }
            }));
        });

        promises.push(axios.post('/app_handler.php', summary, { headers: { 'Wg-Method': 'SAVE_SURVEY_SUMMARY', 'Wg-Key': Cookie.get('accessToken'), 'Wg-RecordId': tmpRecordId }}));

        axios.all(promises)
            .then(axios.spread((...args) => {
                completeSurvey();
            }))
            .catch((error) => {
                setError('The survey wasn\'t fully uploaded. We have a local copy stored ready for when you establish an internet connection');
                setLoading(false);
            });
    }

    const completeSurvey = () => {
        dispatch({ type: SET_SURVEY_COMPLETE });
        history.push('/survey/complete');
    };

    const handleBack = () => {
        history.push('/survey/doors');
    };

    const handleAdjustment = (e) => {
        setAdjustment(e.target.value);
    };

    useEffect(() => {
        const costs = JSON.parse(localStorage.getItem('costs'));

        setSystemCosts(costs);
    }, []);

    useEffect(() => {
        const doorsValues = Object.values(doors);

        setDoorsArr(doorsValues);
    }, [doors]);

    useEffect(() => {
        const localDoors = JSON.parse(localStorage.getItem('doors'));

        if (localDoors && doorsArr.length) {
            const localDoorsArray = Object.values(localDoors);

            if (localDoorsArray.length !== doorsArr.length) {
                history.push('/survey/doors?error');
            } else {
                doorsArr.some((door) => {
                    if (door?.completedFields !== totalFields) {
                        history.push('/survey/doors?error');

                        return true;
                    }
                });
            }
        }
    }, [doorsArr]);

    useEffect(() => {
        if (doorsArr) {
            const total = doorsArr.reduce((acc, current) => {

                if (current?.total) {
                    return acc + current?.total;
                }

                return acc;
            }, 0);

            let failedCosts = 0.00;
            let advisoryCosts = 0.00;
            
            if (systemCosts) {
                doorsArr.map((door) => {
                    let scoresArr = [];

                    if (door?.scores) {
                        for (const [key, value] of Object.entries(door?.scores)) {
                            scoresArr.push({ key, value });
                        }
                    }

                    scoresArr.map((score) => {
                        if (score?.value === "0.0") {
                            failedCosts += parseFloat(systemCosts[score?.key]); 
                        } else if (score?.value === "0.5") {
                            advisoryCosts += parseFloat(systemCosts[score?.key]);
                        }
                    });
                });
            }

            doorsArr.some((door) => {
                if (door?.scores) {
                    const doorScores = Object.values(door?.scores);
        
                    if (doorScores.findIndex(el => el === "0.0") !== -1 || doorScores.findIndex(el => el === "0.5") !== -1) {
                        setIcon('fail');
                        return;
                    }
                }
            });

            let tmpFloorPlans = [];

            Object.values(plans).forEach((plan) => {
                tmpFloorPlans.push({ label: plan.label, doors: doorsArr.filter((door) => door.planRef === plan.id) });
            });

            console.log(tmpFloorPlans);
            
            setFailedCosts(failedCosts);
            setAdvisoryCosts(advisoryCosts);
            setCost(total);
            setTotalCost(total);
            setAdjustment(0);
            setFloorPlansArray(tmpFloorPlans);
        }
    }, [doorsArr, systemCosts]);

    useEffect(() => {
        if (adjustment) {
            setTotalCost(parseFloat((cost + ((cost / 100) * parseInt(adjustment)))).toFixed(2));
        }
    }, [adjustment])

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <div className="form__buttons">
                    <button className="form__button secondary" onClick={handleBack}>Back</button>
                </div>
                <div className="surveyTitle__container">
                    <h1>Survey Summary</h1>
                </div>
                <div className="surveySuccess__container">
                    {icon && icon === 'pass' ? <Check /> : <Cross /> }
                </div>
                <div className="surveyDoors__container">
                    <p style={{ fontWeight: '600' }}>There are items that require attention. A summary can be found below.</p>
                    {floorPlansArray && floorPlansArray.map((plan) => {

                        return (
                            <div>
                                <h4>{plan.label}</h4>
                                <div>
                                    {plan.doors.map((door, i) => {
                                        let doorFailedClass = '';
                                        let doorFailedMessage = '';
                                        let doorAdvisoryClass = '';
                                        let doorAdvisoryMessage = '';
                                        let doorPassClass = '';
                                        let doorPassMessage = '';
                                        let failedFields = [];
                                        let advisoryFields = [];
                
                                        if (door?.scores) {
                                            const scoreValues = Object.values(door?.scores);
                        
                                            if (scoreValues) {
                                                failedFields = scoreValues.filter((score) => score === '0.0');
                                                advisoryFields = scoreValues.filter((score) => score === '0.5');
                                            }
                        
                                            if (!failedFields.length && !advisoryFields.length) {
                                                doorPassClass = 'pass';
                                                doorPassMessage = 'Passed';
                                            }
                        
                                            if (failedFields.length > 0) {
                                                doorFailedClass = 'fail';
                                                doorFailedMessage = `${failedFields.length} Failed`;
                                            }
                                            
                                            if (advisoryFields.length > 0) {
                                                doorAdvisoryClass = 'advisory';
                                                doorAdvisoryMessage = `${advisoryFields.length} Advisory`;
                                            }
                                        }
                
                                        return (
                                            <div key={door?.id} className="surveyDoor__container" style={{ marginTop: i === 0 ? '1.5rem' : '0' }}>
                                                <div>Door {door?.ref && (<span>({door?.ref})</span>)}</div>
                                                <div style={{ marginTop: '1rem' }}>
                                                    {doorFailedMessage && (<div className={doorFailedClass}><span>{doorFailedMessage}</span></div>)}
                                                    {doorAdvisoryMessage && (<div className={doorAdvisoryClass} style={{ marginTop: '1rem' }}><span>{doorAdvisoryMessage}</span></div>)}
                                                    {doorPassMessage && (<div className={doorPassClass} style={{ marginTop: '1rem' }}><span>{doorPassMessage}</span></div>)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="surveyTotal__container">
                    <div className="surveyTotal__row">
                        <div>Cost:</div>
                        <div>£{cost}</div>
                    </div>
                    <div className="surveyTotal__row">
                        <div>Advisory Cost:</div>
                        <div>£{advisoryCosts}</div>
                    </div>
                    <div className="surveyTotal__row">
                        <div>Failed Cost:</div>
                        <div>£{failedCosts}</div>
                    </div>
                    <div className="surveyTotal__row">
                        <div>Adjustment</div>
                        <div><input type="number" value={adjustment} onChange={handleAdjustment} /> %</div>
                    </div>
                    <div className="surveyTotal__row total">
                        <div>Total Cost:</div>
                        <div>£{totalCost}</div>
                    </div>
                </div>
                <p>Please ensure you've completed all necassary changes before you proceed. After you've completed the survey you cannot go back and make further changes.</p>
                <div className="form__buttons">
                    <button className="form__button form__button--inline" disabled={loading} onClick={handleCompleteSurvey}>Complete Survey <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                </div>
                {error && (<div class="error">{error}</div>)}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
