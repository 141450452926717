import axios from 'axios';
import Cookie from 'js-cookie';

import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_RECENT_SURVEYS_FAIL } from '../types/dashboardTypes';

export const getRecentSurveys = () => async dispatch => {
    try {
        dispatch({ type: GET_RECENT_SURVEYS_REQUEST });

        const { data } = await axios.get('/app_handler.php', { headers: { 'WG-Method': 'GET_RECENT_SURVEYS', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_RECENT_SURVEYS_SUCCESS, payload: data?.surveys });

        localStorage.setItem('recentSurveys', JSON.stringify(data?.surveys));
    } catch (error) {
        dispatch({ type: GET_RECENT_SURVEYS_FAIL, payload: error?.response?.data.errors });
    }
};