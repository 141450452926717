import { SET_PIN_REQUEST, SET_PIN_SUCCESS, SET_PIN_FAILURE, UPDATE_PIN_REQUEST, UPDATE_PIN_SUCCESS, UPDATE_PIN_FAILURE, REMOVE_PIN_REQUEST, REMOVE_PIN_SUCCESS, REMOVE_PIN_FAILURE, SET_PIN_REF_REQUEST, SET_PIN_REF_SUCCESS, SET_PIN_REF_FAILURE, GENERATE_PINS_REQUEST, GENERATE_PINS_SUCCESS, GENERATE_PINS_FAILURE, CLEAR_FLOORPLAN, UPLOAD_FLOORPLAN_REQUEST, UPLOAD_FLOORPLAN_SUCCESS, UPLOAD_FLOORPLAN_FAILURE, SAVE_LABEL_REQUEST, SAVE_LABEL_SUCCESS, SAVE_LABEL_FAILURE, SAVE_FLOORPLAN_REQUEST, SAVE_FLOORPLAN_SUCCESS, SAVE_FLOORPLAN_FAILURE } from '../types/floorPlanTypes';

const pins = JSON.parse(localStorage.getItem('floorPlan'));
const plans = JSON.parse(localStorage.getItem('uploadedPlans'));

const initialState = {
    pins: pins || [],
    plans: plans || null,
    loading: false,
    saveLabelLoading: false,
    savePlanLoading: false,
    errors: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case SET_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                pins: [...state.pins, action.payload],
            }
        case SET_PIN_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case UPDATE_PIN_REQUEST:
            return {
                ...state,
            }
        case UPDATE_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                pins: [...state.pins.filter(pin => pin.id !== action.payload.id), action.payload]
            }
        case UPDATE_PIN_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case REMOVE_PIN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case REMOVE_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                pins: [...state.pins.filter(pin => pin.id !== action.payload)]
            }
        case REMOVE_PIN_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case SET_PIN_REF_REQUEST:
            return {
                ...state,
            }
        case SET_PIN_REF_SUCCESS:
            let updatedPin = state.pins.find(pin => pin.id === action.payload.id);

            updatedPin['ref'] = action.payload.ref;

            return {
                ...state,
                pins: [...state.pins.filter(pin => pin.id !== action.payload.id), updatedPin]
            }
        case SET_PIN_REF_FAILURE:
            return {
                ...state,
            }
        case GENERATE_PINS_REQUEST:
            return {
                ...state,
            }
        case GENERATE_PINS_SUCCESS:
            return {
                ...state,
                pins: action.payload,
            }
        case GENERATE_PINS_FAILURE:
            return {
                ...state,
            }
        case UPLOAD_FLOORPLAN_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case UPLOAD_FLOORPLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plan: action.payload,
            }
        case UPLOAD_FLOORPLAN_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case CLEAR_FLOORPLAN:
            return {
                ...state,
                pins: [],
                loading: false,
                errors: null,
            }
        case SAVE_LABEL_REQUEST:
            return {
                ...state,
                saveLabelLoading: true,
            }
        case SAVE_LABEL_SUCCESS:
            let updatedPlans = state.plans;

            // updatedPlans[action.payload.id].label = action.payload.label;

            if (updatedPlans) {
                console.log('1');
                if (updatedPlans[action.payload.id]) {
                    console.log('2');
                    updatedPlans[action.payload.id].label = action.payload.label;
                } else {
                    console.log('3');
                    const newPlan = {
                        [action.payload.id]: {
                            id: action.payload.id,
                            label: action.payload.label,
                            active: true,
                        }
                    }

                    updatedPlans = Object.assign(updatedPlans, newPlan);
                }
            } else {
                console.log('4');
                updatedPlans = {
                    [action.payload.id]: {
                        id: action.payload.id,
                        label: action.payload.label,
                        active: true,
                    }
                } 
            }

            return {
                ...state,
                plans: updatedPlans,
                saveLabelLoading: false,
            }
        case SAVE_LABEL_FAILURE:
            return {
                ...state,
                saveLabelLoading: false,
            }
        case SAVE_FLOORPLAN_REQUEST:
            return {
                ...state,
                savePlanLoading: true,
            }
        case SAVE_FLOORPLAN_SUCCESS:
            let floorPlans = state.plans;

            console.log(action.payload);

            if (floorPlans) {
                console.log('1');
                if (floorPlans[action.payload.id]) {
                    console.log('2');
                    floorPlans[action.payload.id].active = !floorPlans[action.payload.id].active;
                } else {
                    console.log('3');
                    const newPlan = {
                        [action.payload.id]: {
                            id: action.payload.id,
                            label: action.payload.label,
                            active: true,
                        }
                    }

                    floorPlans = Object.assign(floorPlans, newPlan);
                }
            } else {
                console.log('4');
                floorPlans = {
                    [action.payload.id]: {
                        id: action.payload.id,
                        label: action.payload.label,
                        active: true,
                    }
                } 
            }

            return {
                ...state,
                savePlanLoading: false,
                plans: floorPlans,
            }
        case SAVE_FLOORPLAN_FAILURE:
            return {
                ...state,
                savePlanLoading: false,
            }
        default:
            return state;
    }
};