import { GET_RECENT_SURVEYS_REQUEST, GET_RECENT_SURVEYS_SUCCESS, GET_RECENT_SURVEYS_FAIL } from '../types/dashboardTypes';

const surveys = JSON.parse(localStorage.getItem('recentSurveys'));

const initialState = {
    recentSurveys: [],
    loading: false,
    errors: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RECENT_SURVEYS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
                recentSurveys: []
            }
        case GET_RECENT_SURVEYS_SUCCESS:
            return {
                ...state,
                loading: false,
                recentSurveys: action.payload
            }
        case GET_RECENT_SURVEYS_FAIL:
            return {
                ...state,
                loading: false,
                errors: action.payload,
                recentSurveys: surveys
            }
        default:
            return state;
    }
};