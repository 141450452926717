export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const NAVIGATION_REQUEST = 'NAVIGATION_REQUEST';
export const NAVIGATION_SUCCESS = 'NAVIGATION_SUCCESS';
export const NAVIGATION_FAIL = 'NAVIGATION_FAIL';

export const MENU_REQUEST = 'MENU_REQUEST';
export const MENU_SUCCESS = 'MENU_SUCCESS';
export const MENU_FAIL = 'MENU_FAIL';

export const GET_DEPENDENCIES_REQUEST = 'GET_DEPENDENCIES_REQUEST';
export const GET_DEPENDENCIES_SUCCESS = 'GET_DEPENDENCIES_SUCCESS';
export const GET_DEPENDENCIES_FAIL = 'GET_DEPENDENCIES_FAIL';