import axios from 'axios';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase';

import { CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, SAVE_DOOR_REQUEST, SAVE_DOOR_SUCCESS, SAVE_DOOR_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE, ADD_DOOR_REQUEST, ADD_DOOR_SUCCESS, ADD_DOOR_FAILURE, REMOVE_DOOR_REQUEST, REMOVE_DOOR_SUCCESS, REMOVE_DOOR_FAILURE, UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE, CLEAR_DOOR_PROCEED, ASSIGN_PINS_REQUEST, ASSIGN_PINS_SUCCESS, ASSIGN_PINS_FAILURE, SET_ACTIVE_PLAN_REQUEST, SET_ACTIVE_PLAN_SUCCESS, SET_ACTIVE_PLAN_FAILURE, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE } from '../types/surveyTypes';

export const createSurvey = (userId, clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorplan, comments) => async dispatch => {
    const formPayload = { inspection_client_name: clientName, inspection_client_address: clientAddress, inspection_client_telephone: clientTelephone, inspection_client_email: clientEmail, inspection_survey_address: surveyAddress, inspection_building_name: surveyBuilding, inspection_contact_name: contactName, inspection_contact_telephone: contactTelephone, inspection_contact_email: contactEmail, inspection_building_photo: buildingPhoto, inspection_floorplan_photo: floorplan, inspection_comments: comments };
    
    const fd = new FormData();

    fd.append('inspection_client_name', clientName);
    fd.append('inspection_client_address', clientAddress);
    fd.append('inspection_client_telephone', clientTelephone);
    fd.append('inspection_client_email', clientEmail);
    fd.append('inspection_survey_address', surveyAddress);
    fd.append('inspection_building_name', surveyBuilding);
    fd.append('inspection_contact_name', contactName);
    fd.append('inspection_contact_telephone', contactTelephone);
    fd.append('inspection_contact_email', contactEmail);
    if (typeof buildingPhoto === 'object') {
        fd.append('inspection_building_photo', buildingPhoto, buildingPhoto?.name);
    } else {
        fd.append('inspection_building_photo', '');
    }
    if (typeof floorplan === 'object') {
        fd.append('inspection_floorplan_photo', floorplan, floorplan?.name);
    } else {
        fd.append('inspection_floorplan_photo', '');
    }
    fd.append('inspection_comments', comments);

    const surveyId = uuidv4();


    // let survey = JSON.parse(localStorage.getItem('survey'));

    try {
        dispatch({ type: CREATE_SURVEY_REQUEST });

        const { data } = await axios.post('/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_CLIENTDATA', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: CREATE_SURVEY_SUCCESS, payload: { id: surveyId, recordId: data?.record_id, doors: {}, surveyKey: data?.survey_key, floorPlans: data?.floor_plan, proceedToPDFSelector: data?.pdf_page_selector }});
        
        // if (survey) {
        //     survey[surveyId] = { id: surveyId, recordId: data?.record_id, type: 'online', surveyKey: data?.survey_key, doors: doorArr };

        //     localStorage.setItem('survey', JSON.stringify(survey));
        // } else {
        //     localStorage.setItem('survey', JSON.stringify({ [surveyId] : { id: surveyId, recordId: data?.record_id, type: 'online', surveyKey: data?.survey_key, doors: doorArr }}));
        // }

        localStorage.setItem('survey', JSON.stringify({ id: surveyId, userId, recordId: data?.record_id, doors: {}, type: 'online', surveyKey: data?.survey_key }));
        localStorage.setItem('clientData', JSON.stringify(data?.fields));
        // localStorage.setItem('uploadedPlans', JSON.stringify(data?.floor_plan));
    } catch (error) {

        const data = { id: surveyId, userId, doors: {}, type: 'offline', data: formPayload };

        dispatch({ type: CREATE_SURVEY_FAILURE, payload: { id: surveyId } });

        // if (survey) {
        //     survey[surveyId] = data;
        //     localStorage.setItem('survey', JSON.stringify(survey));
        // } else {
        //     localStorage.setItem('survey', JSON.stringify({ [surveyId]: data }));
        // }

        localStorage.setItem('survey', JSON.stringify(data));

        const db = new Localbase('saved-uploads');

        try {
            await db.collection('building-photos').add(buildingPhoto, surveyId);
            await db.collection('floorplan-photos').add(floorplan, surveyId);
        } catch (error) {
            console.log(error);
        }

        localStorage.setItem('clientData', JSON.stringify(formPayload));
    }

    localStorage.setItem('activeSurvey', surveyId);
    
};

export const updateSurvey = (clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorplan, comments, recordId) => async dispatch => {
    const formPayload = { inspection_client_name: clientName, inspection_client_address: clientAddress, inspection_client_telephone: clientTelephone, inspection_client_email: clientEmail, inspection_survey_address: surveyAddress, inspection_building_name: surveyBuilding, inspection_contact_name: contactName, inspection_contact_telephone: contactTelephone, inspection_contact_email: contactEmail, inspection_building_photo: buildingPhoto, inspection_floorplan_photo: floorplan, inspection_comments: comments };

    const fd = new FormData();

    fd.append('inspection_client_name', clientName);
    fd.append('inspection_client_address', clientAddress);
    fd.append('inspection_client_telephone', clientTelephone);
    fd.append('inspection_client_email', clientEmail);
    fd.append('inspection_survey_address', surveyAddress);
    fd.append('inspection_building_name', surveyBuilding);
    fd.append('inspection_contact_name', contactName);
    fd.append('inspection_contact_telephone', contactTelephone);
    fd.append('inspection_contact_email', contactEmail);
    if (typeof buildingPhoto === 'object') {
        fd.append('inspection_building_photo', buildingPhoto, buildingPhoto?.name);
    } else {
        fd.append('inspection_building_photo', '');
    }
    if (typeof floorplan === 'object') {
        fd.append('inspection_floorplan_photo', floorplan, floorplan?.name);
    } else {
        fd.append('inspection_floorplan_photo', '');
    }
    fd.append('inspection_comments', comments);

    try {
        dispatch({ type: UPDATE_SURVEY_REQUEST });

        const { data } = await axios.post('/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_CLIENTDATA', 'WG-Key': Cookie.get('accessToken'), 'WG-RecordId': recordId }});

        dispatch({ type: UPDATE_SURVEY_SUCCESS, payload: { recordId: data?.record_id, surveyKey: data?.survey_key }});

        const surveyId = localStorage.getItem('activeSurvey');
        let survey = JSON.parse(localStorage.getItem('survey'));

        // survey[surveyId].recordId = data?.record_id;
        // survey[surveyId].surveyKey = data?.survey_key;
        survey.recordId = data?.record_id;
        survey.surveyKey = data?.survey_key;

        localStorage.setItem('survey', JSON.stringify(survey));
        localStorage.setItem('clientData', JSON.stringify(data?.fields));

    } catch (error) {
        dispatch({ type: UPDATE_SURVEY_FAILURE });

        const surveyId = localStorage.getItem('activeSurvey');
        
        const db = new Localbase('saved-uploads');

        try {
            await db.collection('building-photos').add(buildingPhoto, surveyId);
            await db.collection('floorplan-photos').add(floorplan, surveyId);
        } catch (error) {
            console.log(error);
        }

        localStorage.setItem('clientData', JSON.stringify(formPayload));
    }
}

export const saveDoor = (formData, id, surveyId, costs, scores, planRef) => async dispatch => {
    let total = 0.00;
    let completedFields = 0;
    let doorId = 0;
    let savedFormData = formData;
    let doorToSave = {};

    const db = new Localbase('saved-uploads');

    try {
        dispatch({ type: SAVE_DOOR_REQUEST });

        const fd = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (value.type === 'file') {
                if (typeof value.value === 'object') {
                    fd.append(key, value.value, value.value.name);
                    
                    db.collection('door-images').add({ key, file: value.value }, `${id}_${key}`);
                } else {
                    fd.append(key, value.value);
                }
            } else {
                fd.append(key, value.value);
            }
        }

        for (const [key, value] of Object.entries(costs)) {
            for (const [formKey, formValue] of Object.entries(formData)) {
                if (formValue.type !== 'file') {
                    if (key === formKey && (parseFloat(scores[key]) === 0.5 || parseFloat(scores[key]) === 0.0)) {
                        fd.append(`costs_${key}`, value);
                        total += parseFloat(value);
                    }

                    if (key === formKey && (parseFloat(scores[key]) === 0.5 || parseFloat(scores[key]) === 0.0 || parseFloat(scores[key]) === 1.0)) {
                        completedFields++;
                    }
                }
            }
        }

        let doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts) {
            doorCosts[id] = costs;

            localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
        } else {
            const newDoorCosts = { [id]: costs };

            localStorage.setItem('doorCosts', JSON.stringify(newDoorCosts));
        }

        let doorScores = JSON.parse(localStorage.getItem('doorScores'));

        if (doorScores) {
            console.log(scores);
            doorScores[id] = scores;

            localStorage.setItem('doorScores', JSON.stringify(doorScores));
        } else {
            const newDoorScores = { [id]: scores };

            localStorage.setItem('doorScores', JSON.stringify(newDoorScores));
        }

        const doors = JSON.parse(localStorage.getItem('doors'));
    
        const { data } = await axios.post('/app_handler.php', fd, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'WG-EntryId': doors[id]?.doorId || id,
                'WG-RecordId': surveyId,
                'Wg-Method': 'SAVE_DOOR',
                'Wg-Key': Cookie.get('accessToken')
            }
        });

        doorId = data?.form_entry_id;
        surveyId = data?.survey_id;

        if (data?.fields) {
            const db = new Localbase('saved-uploads');
            
            for (const [key, value] of Object.entries(formData)) {
                if (data?.fields[key]) {
                    savedFormData[key] = { type: value.type, value: data?.fields[key] };

                    try {

                        const document = await db.collection('door-images').doc(`${id}_${key}`).get();

                        if (document) {
                            await db.collection('door-images').doc(`${id}_${key}`).delete();
                        }

                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }

        console.log(savedFormData);
        console.log(savedFormData['door-reference_5f7c9de1088a0_formid_436']['value']);

        doorToSave = { id, doorId, data: savedFormData, total, planRef, completedFields, scores, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'] };

        console.log(doorToSave);

        dispatch({ type: SAVE_DOOR_SUCCESS, payload: { door: doorToSave, recordId: surveyId }});

    } catch (error) {
        doorToSave = { id, doorId, data: savedFormData, total, planRef, completedFields, scores, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'] };

        dispatch({ type: SAVE_DOOR_FAILURE, payload: { door: doorToSave, recordId: surveyId }});
    }
    
    let door = JSON.parse(localStorage.getItem('doors'));

    if (door) {
        door[id] = { ...door[id], id, doorId, data: savedFormData, total, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

        console.log(door);
        localStorage.setItem('doors', JSON.stringify(door));
    } else {
        const newDoor = { [id]: { id, doorId, data: savedFormData, total, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores } };

        console.log(newDoor);
        localStorage.setItem('doors', JSON.stringify(newDoor));
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    // const activeSurvey = localStorage.getItem('activeSurvey');

    // if (survey[activeSurvey]) {
    //     survey[activeSurvey].recordId = surveyId;

    //     localStorage.setItem('survey', JSON.stringify(survey));
    // }

    if (survey) {
        survey.recordId = surveyId;

        localStorage.setItem('survey', JSON.stringify(survey));
    }
};

export const loadDoor = (id) => async dispatch => {
    try {
        dispatch({ type: LOAD_DOOR_REQUEST });

        const { data } = await axios.get('/app_handler.php', { headers: { 'WG-Method': 'GET_DOOR_TEMPLATE', 'Wg-EntryId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        const dependencies = JSON.parse(localStorage.getItem('dependencies'));
        
        dispatch({ type: LOAD_DOOR_FAILURE, payload: dependencies?.form_templates?.door });
    }
};

export const addDoor = (surveyId, planRef, ref, pinId) => async dispatch => {
    const id = uuidv4();

    const data = { 
        id,
        total: 0.00,
        planRef,
        ref: ref || '',
        pinId,
    }

    try {
        dispatch({ type: ADD_DOOR_REQUEST });

        if (surveyId) {
            await axios.post('/app_handler.php', { data }, { headers: { 'WG-Method': 'ADD_DOOR', 'Wg-SurveyId': surveyId, 'WG-Key': Cookie.get('accessToken') }});
        } else {
            throw 'Survey ID not found';
        }

        dispatch({ type: ADD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADD_DOOR_FAILURE, payload: data });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    // const activeSurvey = localStorage.getItem('activeSurvey');
    let doors = JSON.parse(localStorage.getItem('doors'));
    let clientData = JSON.parse(localStorage.getItem('clientData'));

    // survey[activeSurvey].doors[id] = data;
    if (survey.doors[id]) {
        survey.doors[id] = data;
    } else {
        survey.doors = Object.assign(survey.doors, { [id]: data });
    }

    if (doors) {
        if (doors[id]) {
            doors[id] = data;
        } else {
            doors = Object.assign(doors, { [id]: data });
        }
    } else {
        doors = { [id]: data };
    }

    clientData.inspection_doors = clientData?.inspection_doors + 1;

    localStorage.setItem('survey', JSON.stringify(survey));
    localStorage.setItem('doors', JSON.stringify(doors));
    localStorage.setItem('clientData', JSON.stringify(clientData));
};

export const removeDoor = (doorId, id) => async dispatch => {
    try {
        dispatch({ type: REMOVE_DOOR_REQUEST });

        if (doorId) {
            await axios.post('/app_handler.php', { door_id: doorId }, { headers: { 'WG-Method': 'REMOVE_DOOR', 'Wg-DoorId': doorId, 'WG-Key': Cookie.get('accessToken') }});
        }

        dispatch({ type: REMOVE_DOOR_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: REMOVE_DOOR_FAILURE, payload: error.message });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    // const activeSurvey = localStorage.getItem('activeSurvey');

    // delete survey[activeSurvey].doors[id];
    delete survey.doors[id];

    localStorage.setItem('survey', JSON.stringify(survey));

    let doors = JSON.parse(localStorage.getItem('doors'));

    delete doors[id];

    localStorage.setItem('doors', JSON.stringify(doors));

    let clientData = JSON.parse(localStorage.getItem('clientData'));

    clientData.inspection_doors = clientData?.inspection_doors - 1;

    localStorage.setItem('clientData', JSON.stringify(clientData));
};

export const clearDoorProceed = () => async dispatch => {
    dispatch({ type: CLEAR_DOOR_PROCEED });
};

export const assignPinsToDoors = (pins) => dispatch => {
    try {
        dispatch({ type: ASSIGN_PINS_REQUEST });

        dispatch({ type: ASSIGN_PINS_SUCCESS, payload: pins });
    } catch (error) {
        dispatch({ type: ASSIGN_PINS_FAILURE, payload: error?.response.data.errors });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));

    if (survey?.doors) {
        let index = 0;

        for (const [key, value] of Object.entries(survey.doors)) {
            value.ref = pins[index]?.ref;
            value.pinId = pins[index]?.id;
            index++;
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
}

export const setActivePlan = (id) => dispatch => {
    try {
        dispatch({ type: SET_ACTIVE_PLAN_REQUEST });
        dispatch({ type: SET_ACTIVE_PLAN_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: SET_ACTIVE_PLAN_FAILURE });
    }
}

export const saveDoorRef = (id, ref) => dispatch => {
    try {
        dispatch({ type: SAVE_DOOR_REF_REQUEST });
        dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id, ref } });
    } catch (error) {
        dispatch({ type: SAVE_DOOR_REF_FAILURE });
    }
}