import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Tick } from '../../assets/check.svg';
import { ReactComponent as Exclamation } from '../../assets/exclamation.svg';

const Select = ({ field: { type, name, label, id }, handleChange, handleCosts, handleDefaultCosts, options, handleScore, defaultValue, errorClass }) => {
    const costsSelector = useSelector((state) => state.costs);
    const { costs } = costsSelector;
    const savedCost = costs[name];

    const [cost, setCost] = useState(savedCost || 0.00);
    const [icon, setIcon] = useState(null);
    const [action, setAction] = useState(null);
    const [style, setStyle] = useState('');

    const handleLocalChange = event => {
        handleChange(event);

        const option = options.filter((option) => option.field_option_id === event.target.value)[0];

        setAction(option?.option_description);
        setIcon(option?.icon);
        setStyle(option?.style);
        handleDefaultCosts(name, savedCost);
        handleScore(name, option?.option_score);
    }

    const renderIcon = () => {
        switch (icon) {
            case 'tick':
                return <Tick />;
            case 'warning':
                return <Exclamation />;
            case 'cross':
                return <Cross />;
            default:
                return;
        }
    }

    useEffect(() => {
        const event = { target: { name, value: defaultValue }};

        handleLocalChange(event)
    }, [defaultValue]);

    return (
        <div className={errorClass}>
            <label className="form__label" htmlFor={id}>{label}</label>
            <div className="selectContainer">
                <select className="form__select" id={id} type={type} name={name} onChange={handleLocalChange} defaultValue={defaultValue}>
                    <option value="">-- Please select an option --</option>
                    {options && options.map((option) => (
                        <option value={option?.field_option_id}>{option?.option_name}</option>
                    ))}
                </select>
                <div className="actionContainer">
                    {action && (
                        <>
                            <div className={`action ${style}`}>{renderIcon}</div>
                            <div className={style}>{action}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Select;
