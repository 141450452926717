import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import Input from '../../components/forms/Input';

import { loadCosts, setCosts } from '../../actions/costsActions';

import './styles/Costs.css';

export const Costs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const costsSelector = useSelector((state) => state.costs);
    const [formData, setFormData] = useState(null);
    const [formFields, setFormFields] = useState([]);
    const { costs, form } = costsSelector;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value });
    }

    const onSubmit = (event) => {
        event.preventDefault();

        dispatch(setCosts(formData));
        history.push('/');
    }

    useEffect(() => {
        dispatch(loadCosts());
    }, []);

    useEffect(() => {
        if (formFields) {
            let formTmp = {};
            
            for (const [key, value] of Object.entries(costs)) {
                if (formFields.includes(key)) {
                    formTmp[key] = value;
                }
            }
    
            setFormData(formTmp);
        }
    }, [costs, formFields]);

    useEffect(() => {
        const tmpFields = [];

        const tmp = form.map((section) => {
            const tmpSection = section?.fields.map((field) => {
                tmpFields.push(field?.name);
            });
        });

        setFormFields(tmpFields);
    }, [form]);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <h1>Costs</h1>
                <p style={{ textAlign: 'center' }}>Please specify the costs applicable for any door repairs</p>
                <form className="costsForm" onSubmit={onSubmit}>
                    <Fragment>
                        {form.map((section) => (
                            <Fragment>
                                {section?.label && (<div className="form__title">{section?.label}</div>)}
                                <div>
                                    {section?.fields && section.fields.map((field) => {
                                        const value = costs[field?.name] || '';

                                        return <Input field={field} value={value} handleChange={handleChange} />
                                    })}
                                </div>
                            </Fragment>
                        ))}
                        <div className="form__buttons">
                            <button className="form__button" type="submit">Submit</button>
                        </div>
                    </Fragment>
                </form>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
