import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import Input from '../../components/forms/Input';
import File from '../../components/forms/File';
import Textarea from '../../components/forms/Textarea';
import Select from '../../components/forms/Select';

import { loadDoor, saveDoor, clearDoorProceed } from '../../actions/surveyActions';

export const Door = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState(null);
    const [previews, setPreviews] = useState({});
    const [costs, setCosts] = useState(null);
    const [scores, setScores] = useState(null);
    const [missingFields, setMissingFields] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const surveySelector = useSelector((state) => state.survey);
    const { recordId, doors, doorLoading, doorFormLoading, doorFormProceed, doorForm, activePlan } = surveySelector;

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { pins } = floorPlanSelector;
    
    const doorCosts = JSON.parse(localStorage.getItem('doorCosts')) || {};
    const doorScores = JSON.parse(localStorage.getItem('doorScores')) || {};

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData({...formData, [name]: { type: 'text', value: value }});
    }

    const handleCosts = (name, cost) => {
        setCosts({ ...costs, [name]: cost });
    }

    const fileSelectedHandler = (event) => {
        const { name, files } = event.target;
        setFormData({ ...formData, [name]: { type: 'file', value: files[0] }});
        setPreviews({ ...previews, [name]: URL.createObjectURL(files[0]) });
    }

    const handleScore = (name, score) => {
        setScores({ ...scores, [name]: score });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(saveDoor(formData, id, recordId, costs, scores, activePlan));
    }

    useEffect(() => {
        if (doorForm) {
            let initialData = {};

            doorForm.sections && doorForm.sections.map((section) => {
                section.fields.map((field) => {
                    let value = doors?.[id]?.data?.[field?.name]?.value || '';

                    initialData = {...initialData, [field?.name]: { type: field?.type || field?.component, value: typeof value === 'object' ? '' : value }};
                });
            });

            setFormData(initialData);

            let costsTmp = {};
        
            if (doorCosts[id]) {
                for (const [key, value] of Object.entries(doorCosts[id])) {
                    costsTmp[key] = value;
                }
            }
    
            setCosts(costsTmp);
            
            let scoresTmp = {};
        
            if (doorScores[id]) {
                for (const [key, value] of Object.entries(doorScores[id])) {
                    if (Object.keys(costsTmp).includes(key)) {
                        scoresTmp[key] = value;
                    }
                }
            }
    
            setScores(scoresTmp);
        }
    }, [doorForm]);

    useEffect(() => {
        dispatch(loadDoor(id));

        return (() => {
            setFormData(null);
            setCosts(null);
            setScores(null);
        });
    }, []);

    useEffect(() => {
        if (doorFormProceed) {
            history.push('/survey/doors');
            dispatch(clearDoorProceed());
        }
    }, [doorFormProceed]);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content style={{ marginTop: '148.77px' }} >
                <div>
                    <div className="form__buttons" style={{ justifyContent: 'flex-start' }}>
                        <button className="form__button" disabled={doorLoading} onClick={() => history.push('/survey/doors')}>Back</button>
                    </div>
                    {!doorFormLoading && missingFields && (
                        <div style={{ marginTop: '1rem', textAlign: 'center' }} className="fail">You have incomplete fields. Please correct the highlighted fields to continue.</div>
                    )}
                    {doorFormLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <form className="form" onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="form__instructions">{doorForm?.before_text}</div>
                            {doorForm?.sections && doorForm?.sections.map((section) => (
                                <Fragment>
                                    {section?.label && (<div className="form__title">{section?.label}</div>)}
                                    <div>
                                        {section?.fields && section.fields.map((field) => {
                                            let renderedField;
                                        
                                            if (field?.component === 'image' || field?.component === 'image_multiple') {
                                                let value = '';
                                                
                                                if (doors[id]) {
                                                    value = doors[id]?.data?.[field?.name]?.value || '';
                                                }

                                                renderedField = <File field={field} existingFile={value} fileSelectedHandler={fileSelectedHandler} previews={previews} />
                                            } else if (field?.component === 'textarea') {
                                                renderedField = <Textarea field={field} handleChange={handleChange} />
                                            } else if (field?.component === 'select') {
                                                let value = '';
                                                let errorClass = '';

                                                if (doors?.[id]?.data) {
                                                    value = doors?.[id]?.data?.[field?.name]?.value || '';

                                                    errorClass = !doors?.[id]?.data?.[field?.name]?.value ? 'field-incompleted show' : '';

                                                    if (!doors?.[id]?.data?.[field?.name]?.value && !missingFields) {
                                                        setMissingFields(true);
                                                    }
                                                }

                                                renderedField = <Select errorClass={errorClass} field={field} defaultValue={value} handleChange={handleChange} handleCosts={handleCosts} handleDefaultCosts={handleCosts} handleScore={handleScore} options={field?.options} />
                                            } else {
                                                let value = '';
                                                
                                                if (doors[id]) {
                                                    value = doors[id]?.data?.[field?.name]?.value || '';
                                                }

                                                if (field?.name === 'door-reference_5f7c9de1088a0_formid_436' && !value) {
                                                    value = doors[id]?.data?.[field?.name]?.value || doors[id]?.ref;
                                                }

                                                renderedField = <Input field={field} value={value} handleChange={handleChange} />
                                            }
                                        
                                            return renderedField;
                                        })}
                                    </div>
                                </Fragment>
                            ))}
                            <div className="form__buttons">
                                <button aria-label="Submit" disabled={doorLoading} className="form__button form__button--inline" type="submit">Submit <PulseLoader loading={doorLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                            </div>
                        </form>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
